@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

.search-bar-container {
    width: 100%;
    padding: 10px 0;
    padding-left: 1rem;
    border-bottom: 2px solid #0A4650;
}

.date-range-selector input[type="date"] {
    width: 130px;
}

.search-and-filter {
    max-width: 500px;
}

.search-input {
    border-right: none;
}

.search-icon-container {
    background-color: #fff;
    border-left: none;
}

.search-icon {
    color: #6c757d;
}

.filter-button {
    margin-right: 1rem;
    padding: 6px 12px;
}

.filter-button i {
    font-size: 1.2rem;
}

@media (max-width: 992px) {
    .search-bar-container {
        flex-direction: column;
        align-items: stretch !important;
    }

    .date-range-selector,
    .search-and-filter {
        width: 100%;
        margin-bottom: 10px;
    }
}


.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .dropdown-menu.show {
    display: block;
    margin-top: 0.5rem;
  }
  
  .form-check-label {
    cursor: pointer;
  }