.kv-table-container {
    padding: 10px;
}

.kv-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
}

.kv-table th,
.kv-table td {
    padding: 5px;
    text-align: left;
}

.kv-table th {
    font-weight: bold;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.pagination-button {
    background-color: #0A4650;
    border: none;
    color: white;
    margin: 0 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.pagination-button:hover {
    background-color: #45a049;
}

.pagination-button:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
}

.page-info {
    font-size: 16px;
}