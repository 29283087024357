.main-page {
  margin-left: 5rem;
}

@media (max-width: 550px) {
  .main-page {
    margin-left: 1rem;
  }
}

.dashboard {
  margin: 1rem;
}


.chart_container {
  background-color: #F5F5F5;
  min-height: 100px;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}