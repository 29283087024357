/* src/TopNavBar.css */
.navbar {
    border-bottom: 2px solid #0A4650;
    padding-left: 2rem;
    padding-right: 2rem;
}

.navbar-user {
    display: flex;
    align-items: center;
}

.navbar-user h6 {
    margin: 0;
    padding-right: 0.5rem;
    color: #0A4650;
}

.navbar-user .down-arrow {
    font-size: 1.2rem;
}


.navbar-avatar {
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-right: 1rem;
    object-fit: cover;
    display: flex;
    align-items: center;
}