@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loading-animation {
    animation: fadeIn 0.5s ease-in-out;
}