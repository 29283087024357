.sidebar {
    width: 5rem;
    background-color: #0A4650;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    /* justify-content: center; */
    align-items: center;
}

.sidebar img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}

.sidebar a {
    color: #BBEB02;
    margin-bottom: 20px;
    font-size: 3rem;
    text-decoration: none;
    text-align: center;
}

.sidebar-text {
    font-size: 10px;
    text-align: center;
}

.sidebar a:last-child {
    margin-bottom: 0;
}

@media (max-width: 550px) {
    .sidebar {
        display: none;
    }
}